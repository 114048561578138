"use client"
import { useState } from "react";
import Image from "next/image";
import Link from "next/link";

export default function ManualSection() {
  // State to track if the radio button is checked
  const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false);
  // State to track if the user attempted to download without acknowledging
  const [showValidation, setShowValidation] = useState<boolean>(false);

  // Handle the radio button change event
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAcknowledged(event.target.checked);
    setShowValidation(false); // Clear validation when acknowledgment is checked
  };

  // Handle the download button click
  const handleDownloadClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!isAcknowledged) {
      e.preventDefault(); // Prevent the link from navigating
      setShowValidation(true); // Show validation message
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top for mobile users
    }
  };

  return (
    <div className="manual-section-wrapper">
      <div className="container px-3">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-center mx-auto w-100 my-3">
          <div className="text-white terms text-center text-md-left">
            <div className="d-flex flex-row">
              <div className="me-2">
                <input
                  type="radio"
                  data-name="Radio"
                  id="radio"
                  name="radio"
                  data-w-id="52cc89e5-c9fb-5a49-9efc-2549b05867a0"
                  className="w-form-formradioinput radio w-radio-input"
                  value="Radio"
                  onChange={handleRadioChange}
                />
              </div>
              <div>
                I acknowledge that I have read{" "}
                <Link href="/policies/safety-terms-of-use" style={{ fontSize: "0.65rem" }} target="_blank">
                  the safety information,               
                </Link>
                <Link href="/policies/terms-of-service" style={{ fontSize: "0.65rem" }} target="_blank">
                  terms and conditions of use, warranty, 
                </Link>{" "}
                and returns prior to downloading a manual.
              </div>
            </div>
            {showValidation && (
              <div className="text-danger mt-2" style={{ fontSize: "0.75rem" }}>
                Please acknowledge the safety information, terms and conditions before downloading.
              </div>
            )}
          </div>
        </div>
        {/* Conditionally enable download sections based on acknowledgment */}
        <div className="card bg-black mb-4">
          <div className="card bg-black mt-2 p-2 mx-auto text-center">
            <h6 className="text-white">The Mark</h6>
            <a
              href="https://aletha-public-assets.s3.us-east-2.amazonaws.com/prod-website-manuals/theMarkManual.pdf"
              target="_blank"
              className="btn btn-primary manual-button"
              onClick={handleDownloadClick}
            >
              Download
            </a>
          </div>
          <div className="card-body text-center mx-auto">
            <div className="image-wrapper">
              <Image
                src="/assets/images/mark.png"
                alt="image"
                width={0}
                height={0}
                sizes="100vw"
                style={{ width: "100%", height: "auto" }}
                className="img-fluid orbit-image"
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-6 mb-4 mb-md-0">
            <div className="card bg-black">
              <div className="card bg-black mt-2 p-2 mx-auto text-center">
                <h6 className="text-white">The Orbit</h6>
                <a
                  href="https://aletha-public-assets.s3.us-east-2.amazonaws.com/prod-website-manuals/theOrbitManual.pdf"
                  target="_blank"
                  className="btn btn-primary manual-button"
                  onClick={handleDownloadClick}
                >
                  Download
                </a>
              </div>
              <div className="card-body text-center mx-auto">
                <div className="image-wrapper">
                  <Image
                    src="/assets/images/orbit-section.png"
                    alt="image"
                    width={0}
                    height={0}
                    sizes="100vw"
                    style={{ width: "100%", height: "auto" }}
                    className="img-fluid orbit-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card bg-black">
              <div className="card bg-black mt-2 p-2 mx-auto text-center">
                <h6 className="text-white">The Range</h6>
                <a
                  href="https://aletha-public-assets.s3.us-east-2.amazonaws.com/prod-website-manuals/theRangeManual.pdf"
                  target="_blank"
                  className="btn btn-primary manual-button"
                  onClick={handleDownloadClick}
                >
                  Download
                </a>
              </div>
              <div className="card-body text-center mx-auto">
                <div className="image-wrapper">
                  <Image
                    src="/assets/images/range.png"
                    alt="image"
                    width={0}
                    height={0}
                    sizes="100vw"
                    style={{ width: "100%", height: "auto" }}
                    className="img-fluid orbit-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card bg-black">
          <div className="card bg-black mt-2 p-2 mx-auto text-center">
            <h6 className="text-white">The Band</h6>
            <a
              href="https://aletha-public-assets.s3.us-east-2.amazonaws.com/prod-website-manuals/theBandManual.pdf"
              target="_blank"
              className="btn btn-primary manual-button"
              onClick={handleDownloadClick}
            >
              Download
            </a>
          </div>
          <div className="card-body text-center mx-auto">
            <div className="image-wrapper">
              <Image
                src="/assets/images/band.png"
                alt="image"
                width={0}
                height={0}
                sizes="100vw"
                style={{ width: "100%", height: "auto" }}
                className="img-fluid orbit-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
